import { CheckUnderIcon } from "assets/svgs";
import styles from "styles/pages/progress/guide.module.scss";

export default function Guide() {
  return (
    <div className={styles.wrapper}>
      <h2>
        <span>
          <CheckUnderIcon />
        </span>
        <span className={styles.txt}>최종확인 해주세요!</span>
      </h2>

      <p>
        모든 데이터는 익명화 하여 기업에 전송되며,
        <br />
        기업은 특정 사용자를 구분할 수 없습니다.
      </p>
    </div>
  );
}
