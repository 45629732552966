import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import useModal from "hooks/useModal";

import { Modal } from "common/modal";
import ModalContent from "./modalContent";
import Info from "./info";
import ItemList from "./itemList";

import styles from "styles/pages/list/index.module.scss";

export default function List() {
  const [isOpen, onToggle] = useModal(true);
  const [cookies, setCookies] = useCookies();
  const [hasCookie, setHasCookie] = useState(true);

  const getExpirationDate = (days: number) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  };

  const onCloseWithExpiration = () => {
    if (!cookies) return;

    const expires = getExpirationDate(365);
    setCookies("MODAL_EXPIRES", true, { path: "/", expires });

    onToggle();
  };

  useEffect(() => {
    if (cookies.MODAL_EXPIRES) return;

    setHasCookie(false);
  }, [cookies.MODAL_EXPIRES]);

  if (isOpen && cookies.MODAL_EXPIRES) onToggle();

  return (
    <div className={styles.wrapper}>
      <Info />
      <ItemList />

      {!hasCookie && (
        <Modal isOpen={isOpen} onToggle={onToggle}>
          <ModalContent onCloseWithExpiration={onCloseWithExpiration} onToggle={onToggle} />
        </Modal>
      )}
    </div>
  );
}
