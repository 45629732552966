import { CheckIcon, InfoIcon } from "assets/svgs";
import DescriptionList from "common/DescriptionList";
import { Guide } from "common/guide";
import styles from "styles/pages/detail/dataInfo.module.scss";
import DataBox from "./DataBox";

type TProps = {
  data: {
    category: number[];
    purpose: string;
  };
};

export default function DataInfo({ data }: TProps) {
  const { purpose } = data;

  const dataList = [
    {
      title: "거래 대상",
      value: (
        <div className={styles.target}>
          <div>마이디에 아래 데이터를 모두 연결한 사용자</div>
          <ul>
            <li>
              의료데이터(건강검진 기록) <CheckIcon className={styles.icon} />
            </li>
            <li>
              의료데이터(병원진료 기록 및 처방 기록) <CheckIcon className={styles.icon} />
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "거래 데이터",
      value: (
        <div className={styles.dataContainer}>
          <DataBox />
          <DataBox />
        </div>
      ),
    },
    {
      title: "데이터 보유 기간",
      value: (
        <p>
          판매일로부터 1년 <span className={styles.highlight}>(365일)</span>
        </p>
      ),
    },
    { title: "목적", value: purpose },
  ];

  return (
    <section className={styles.wrapper}>
      <div className={styles.guideBox}>
        <Guide>
          <div>
            <div className={styles.txtBox}>
              <InfoIcon className={styles.icon} />
              <p>
                식별 정보는 <span className={styles.highlight}>익명화</span>하여 거래됩니다.
              </p>
            </div>
            <p className={styles.example}>예시) 홍길동 -&gt; ***</p>
          </div>
        </Guide>
      </div>

      <DescriptionList dataList={dataList} />
    </section>
  );
}
