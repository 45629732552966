import { Button } from "common/button";

import { ReactComponent as Good } from "assets/svgs/good.svg";
import styles from "styles/pages/point/convertConfirmContent.module.scss";

type TProps = {
  onConfirm?: () => void;
  onCancel?: () => void;
  content: "confirm" | "result";
  convertUnit: string;
};

function ConvertConfirmContent({ onConfirm, onCancel, content, convertUnit }: TProps) {
  return (
    <div className={styles.container}>
      {content === "confirm" && (
        <div className={styles.confirm}>
          <h2>포인트 전환 확인</h2>
          <span>포인트 전환을 진행하시겠습니까?</span>
          <div>
            <Button onClick={onCancel} type="button" size="small" color="white">
              취소
            </Button>
            <Button onClick={onConfirm} type="button" size="small" color="black">
              전환하기
            </Button>
          </div>
        </div>
      )}
      {content === "result" && (
        <div className={styles.result}>
          <h2>
            포인트 전환
            <Good />
          </h2>
          <span>{convertUnit}으로 전환이 완료되었습니다!</span>
        </div>
      )}
    </div>
  );
}

export default ConvertConfirmContent;
