import { ReactComponent as Logo } from "assets/svgs/logo.svg";
import { Button } from "common/button";

import styles from "styles/pages/point/detailContent.module.scss";

type TProps = {
  content: "transaction" | "question";
};

const contentMap = {
  transaction: (
    <p className={styles.text}>
      더 자세한 거래내역은 <br />
      <span>마이디 앱</span>을 통해서
      <br /> 확인하실 수 있습니다.
    </p>
  ),
  question: (
    <p className={styles.text}>
      <span>마이디 앱 설치 후</span> <br />
      마이디 내 <strong>[문의하기]</strong>에
      <br />
      자세한 내용을 등록해주시면
      <br />더 나은 상담이가능합니다.
    </p>
  ),
};

function DetailContent({ content }: TProps) {
  return (
    <div className={styles.container}>
      <Logo />
      {contentMap[content]}
      <Button size="medium" color="red">
        <a href="https://snplab.io/">앱 설치하기</a>
      </Button>
    </div>
  );
}

export default DetailContent;
