import { ReactNode } from "react";
import styles from "styles/common/descriptionList.module.scss";

type TDescription = {
  title: string;
  value: ReactNode;
};

type TProps = {
  dataList: TDescription[];
};

export default function DescriptionList({ dataList }: TProps) {
  return (
    <dl className={styles.wrapper}>
      {dataList.map(({ title, value }) => (
        <div key={`${title}-dl`}>
          <dt>{title}</dt>
          <dd>{value}</dd>
        </div>
      ))}
    </dl>
  );
}
