import { useEffect, useState } from "react";

export default function useModal(defaultOpenOption: boolean = false): [boolean, () => void] {
  const [isOpen, setIsOpen] = useState(defaultOpenOption);
  const onToggle = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isOpen]);

  return [isOpen, onToggle];
}
