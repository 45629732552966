import styles from "styles/pages/progress/result.module.scss";
import confettiImg from "assets/imgs/confetti.png";
import { Button } from "common/button";
import { useNavigate } from "react-router-dom";

export default function Result() {
  const navigate = useNavigate();
  const handleClcik = () => {
    navigate("/point");
  };

  return (
    <section className={styles.wrapper}>
      <img src={confettiImg} alt="confetti" />

      <div className={styles.txtBox}>
        <p>
          PUMP와 거래가
          <br />
          완료되었습니다.
        </p>
        <p>
          지급된 포인트를 확인해 주세요.
          <br />
          자세한 내용은 거래 내역에서 확인할 수 있습니다.
        </p>
      </div>

      <div>
        <Button type="button" size="large" onClick={handleClcik}>
          확인
        </Button>
      </div>
    </section>
  );
}
