import PROPOSAL from "constants/proposal_sample.json";
import { TProposal } from "types/list";

export const getProposalList = async (): Promise<TProposal[]> => {
  try {
    return PROPOSAL as TProposal[];
  } catch (err) {
    throw new Error();
  }
};

export const getDetailData = async (id: string) => {
  const data = PROPOSAL.find((item) => item.ProposalID === id);

  return data;
};
