import { Button } from "common/button";

import styles from "styles/pages/list/modalContent.module.scss";
import { ReactComponent as Security } from "assets/svgs/security.svg";
import { ReactComponent as Logo } from "assets/svgs/logo_myd.svg";

type TProps = {
  onToggle: () => void;
  onCloseWithExpiration: () => void;
};

export default function ModalContent({ onToggle, onCloseWithExpiration }: TProps) {
  return (
    <div className={styles.wrapper}>
      <Security />

      <div className={styles.title}>
        <h2>
          <Logo />
          <span> 데이터 마켓</span>
        </h2>
        <h2>안심하고 거래하세요!</h2>
      </div>

      <p className={styles.guide}>
        모든 데이터는 개인을 식별할 수 없도록
        <br />
        <span>익명화</span>하여 기업에 전송되며,
        <br />
        기업은 특정 사용자를 구분할 수 없습니다.
      </p>

      <div className={styles.userInfo}>
        <p>이름 : 홍길동 → ***</p>
        <p>생년월일 : 20220101 → 2022****</p>
      </div>

      <div className={styles.btns}>
        <Button type="button" size="medium" color="white" onClick={onCloseWithExpiration}>
          다시 보지 않기
        </Button>
        <Button type="button" size="medium" color="black" onClick={onToggle}>
          닫기
        </Button>
      </div>
    </div>
  );
}
