export const getCommasInNum = (value: string | number) => String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const getDelCommas = (value: string) => value.replace(/,/gi, "");
export const getOnlyNumber = (value: string) => Number(value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"));
// replace(/\D/g, "")

export const range = (start: number, end: number) => {
  const array = [];
  for (let i = start; i <= end; ++i) {
    array.push(i);
  }
  return array;
};

export const makeQueryString = (data: any) =>
  Object.entries(data)
    .map((item) => item.join("="))
    .join("&");

/**
 *
 * @param sec ms
 * @returns Promise
 */
// eslint-disable-next-line no-promise-executor-return
export const delay = (sec: number) => new Promise((resolve) => setTimeout(resolve, sec));

export const addComma = (num: number) => Number(num).toLocaleString();

export const setScreenSize = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};
