import { useQuery } from "react-query";

import { getProposalList } from "api";

import Item from "./item";

export default function ItemList() {
  const { data } = useQuery("proposalList", getProposalList);

  return (
    <section role="list">
      {data?.map((item) => (
        <Item key={item.ProposalID} item={item} />
      ))}
    </section>
  );
}
