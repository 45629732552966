import { useNavigate, useParams } from "react-router-dom";
import styles from "styles/pages/detail/summary.module.scss";
import { Button } from "common/button";
import { getCommasInNum } from "utils";

type TProps = {
  data: { imgUrl: string; title: string; amount: number; notBefore: string; notAfter: string };
};

export default function Summary({ data }: TProps) {
  const { imgUrl, title, amount, notBefore, notAfter } = data;
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const handleClcik = () => {
    navigate(`/progress/${id}`);
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.imgBox}>
        <img src={imgUrl} alt="medical" />
      </div>

      <div className={styles.cotents}>
        <div className={styles.title}>{title}</div>
        <div className={styles.point}>{getCommasInNum(amount)}P</div>
        <div className={styles.date}>
          <span>기간 : </span>
          <span>
            {notBefore} ~ {notAfter}
          </span>
        </div>

        <div className={styles.btnBox}>
          <Button type="button" size="large" onClick={handleClcik}>
            거래 진행하기
          </Button>
        </div>
      </div>
    </section>
  );
}
