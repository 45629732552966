import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import { Guide } from "common/guide";

import { getProposalList } from "api";
import styles from "styles/pages/list/info.module.scss";
import { ReactComponent as PointIcon } from "assets/svgs/point.svg";
import { ReactComponent as InfoIcon } from "assets/svgs/info.svg";

export default function Info() {
  const { data } = useQuery("proposalList", getProposalList);

  const navigate = useNavigate();

  const goToPoint = () => {
    navigate("/point");
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.infoText}>
        <h2>{`총 ${data?.length}건`}</h2>
        <button type="button" className={styles.point} onClick={goToPoint}>
          <PointIcon />
          <span>{Number("123456").toLocaleString()}</span>
        </button>
      </div>

      <Guide>
        <div className={styles.icon}>
          <InfoIcon />
        </div>
        <p>
          모든 데이터는 <span>익명화</span>하여 기업에 전송되며,
          <br /> 기업은 특정 사용자를 구분할 수 없습니다.
        </p>
      </Guide>
    </section>
  );
}
