import { ChangeEventHandler, FormEventHandler, MouseEventHandler, ReactNode, useEffect, useState } from "react";
import cx from "classnames";

import { Button } from "common/button";
import ConvertConfirmContent from "./ConvertConfirmContent";
import { Modal } from "common/modal";
import useModal from "hooks/useModal";

import { ReactComponent as ConvertArrow } from "assets/svgs/convert_arrow.svg";
import { ReactComponent as Myd } from "assets/svgs/myd.svg";
import { ReactComponent as Remove } from "assets/svgs/remove.svg";
import { ReactComponent as RemoveError } from "assets/svgs/remove_error.svg";

import styles from "styles/pages/point/convert.module.scss";
import { addComma, getDelCommas } from "utils";

const amounts = ["1,000P", "5,000P", "10,000P", "전액"];

type TProps = {
  companyPointLogo: ReactNode;
  unit: string;
  currPoint: number;
  converter: (point: number) => number;
};

function Convert({ companyPointLogo, unit, currPoint, converter }: TProps) {
  const [convertPoint, setConvertPoint] = useState(0);
  const [displayPoint, setDisplayPoint] = useState(0);
  const [error, setError] = useState("");
  const [modalContent, setModalContent] = useState<"confirm" | "result">("confirm");

  const [isOpen, onToggle] = useModal();

  // TODO 가진 포인트 불러오기
  const toNumber = (str: string) => {
    if (str.endsWith("P")) {
      return Number(
        str
          .split("")
          .slice(0, str.length - 1)
          .join("")
      );
    }
    return +str;
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    const noCommaValue = Number(getDelCommas(value));

    if (noCommaValue > currPoint) {
      setError(`보유한 마이디 포인트보다 초과하여 입력할 수 없습니다.`);
    }
    setConvertPoint(noCommaValue);
  };

  const handleAmountClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!(e.target instanceof HTMLButtonElement)) {
      return;
    }

    const { value } = e.target;
    const sum = convertPoint + toNumber(getDelCommas(value));

    if (value === "전액") {
      setConvertPoint(currPoint);
      return;
    }
    if (sum > currPoint) {
      setError(`보유한 마이디 포인트보다 초과하여 입력할 수 없습니다.`);
    }
    setConvertPoint(sum);
  };

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    if (!convertPoint) {
      setError(`전환할 포인트를 입력해주세요.`);
      return;
    }
    if (!error) {
      onToggle();
    }
  };

  const handleConfirmClick = () => {
    // 요청 api
    setModalContent("result");
    setConvertPoint(0);
  };

  const handleCancelClick = () => {
    onToggle();
  };

  const resetInput = () => {
    setConvertPoint(0);
    setError("");
  };

  useEffect(() => {
    const availableValue = convertPoint <= currPoint;
    if (availableValue) {
      setDisplayPoint(convertPoint);
    }
  }, [convertPoint]);

  useEffect(() => {
    if (convertPoint <= currPoint) {
      setError("");
    }
  }, [convertPoint]);

  useEffect(() => {
    if (!isOpen) {
      setModalContent("confirm");
    }
  }, [isOpen]);

  const showOptions = convertPoint !== 0;
  const convertInputValue = addComma(convertPoint) === "0" ? "" : addComma(convertPoint);
  const remainPoints = currPoint - convertPoint;
  const isError = error !== "";
  const displayRemainPoints = remainPoints >= 0 ? remainPoints : 0;

  return (
    <div className={styles.container}>
      {/* Display */}
      <div className={styles.display}>
        <div>
          <Myd />
          <span data-testid="display-point">{addComma(displayPoint)}P</span>
        </div>
        <ConvertArrow className={styles.arrow} />
        <div>
          {companyPointLogo || `POINT`}
          <span data-testid="display-convert-point">
            {addComma(converter(Number(displayPoint)))}
            {unit}
          </span>
        </div>
      </div>
      <form onSubmit={handleSubmit} className={styles.convert}>
        {/* PointControl */}
        <div className={styles.control}>
          <input
            className={cx({ [styles.error]: !!error }, { [styles.small_padding]: !showOptions })}
            type="text"
            placeholder="전환할 마이디 포인트를 입력해주세요"
            onChange={handleChange}
            value={convertInputValue}
          />
          {showOptions && (
            // Input options
            <div className={styles.show_options}>
              <span className={cx(styles.point_unit, { [styles.error]: !!error })}>P</span>
              {isError ? <RemoveError onClick={resetInput} /> : <Remove onClick={resetInput} />}
            </div>
          )}
          <span className={styles.error_msg}>{error}</span>
          <span className={styles.remain_point}>보유 포인트 : {displayRemainPoints} P</span>
          <div>
            {/* Amount */}
            {amounts.map((amount, i) => (
              <Button
                key={i}
                value={amount}
                type="button"
                size="reactive"
                color="light-grey"
                onClick={handleAmountClick}
              >
                {amount !== "전액" ? "+" : ""}
                {amount}
              </Button>
            ))}
          </div>
        </div>
        <Button type="submit" size="large" color="black">
          전환하기
        </Button>
      </form>
      <Modal isOpen={isOpen} onToggle={onToggle}>
        <ConvertConfirmContent
          onConfirm={handleConfirmClick}
          onCancel={handleCancelClick}
          content={modalContent}
          convertUnit="씨앗"
        />
      </Modal>
    </div>
  );
}

export default Convert;
