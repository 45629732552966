import { useNavigate } from "react-router-dom";

import styles from "styles/pages/list/item.module.scss";

import { TProposal } from "types/list";

export default function Item({ item }: { item: TProposal }) {
  const navigate = useNavigate();

  const goToDetail = () => {
    navigate(`/detail/${item.ProposalID}`);
  };

  const today = new Date();
  const duration = new Date(item.Duration);
  const diff = duration.getTime() - today.getTime();

  const dDay = Math.floor(diff / (1000 * 60 * 60 * 24)) + 1;

  return (
    <article role="presentation" className={styles.wrapper} onClick={goToDetail}>
      <div className={styles.bannerImgBox}>
        <img className={styles.bannerImg} src={item.Content.ImageURL.Banner} alt="proposal_banner" />
      </div>

      <div className={styles.name}>
        <span>{dDay >= 0 ? `[D-${dDay}]` : `[D+${-dDay}]`}</span>
        <span className={styles.title}>{item.Content.Description.Title}</span>
      </div>

      <div className={styles.info}>
        <div className={styles.companyInfo}>
          <img className={styles.companyLogo} src={item.Content.ImageURL.Icon} alt="company_logo" />
          <span>{item.Content.Description.Company.Name}</span>
        </div>
        <span className={styles.point}>{`+${item.RewardPolicy.Amount.toLocaleString()}P`}</span>
      </div>
    </article>
  );
}
