import cx from "classnames";

import { TTransactionList } from "types/point";
import { ReactComponent as ArrowVertical } from "assets/svgs/arrow_vertical.svg";
import { ReactComponent as Exclamation } from "assets/svgs/exclamation.svg";
import styles from "styles/pages/point/transactionList.module.scss";
import { MouseEventHandler, useState } from "react";
import { addComma } from "utils";

type TProps = {
  transactionList: TTransactionList[];
  handleTransactionDetailClick: MouseEventHandler<SVGElement>;
};

function TransactionList({ transactionList, handleTransactionDetailClick }: TProps) {
  const [isListOpen, setIsOpen] = useState(false);

  const handleListOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={styles.list}>
      <button type="button" className={styles.recent_data} onClick={handleListOpen}>
        <div className={styles.left}>
          최근 1개월 거래내역 (총 {transactionList.length}건)
          <Exclamation onClick={handleTransactionDetailClick} />
        </div>
        <ArrowVertical className={cx({ [styles.down]: isListOpen }, { [styles.up]: !isListOpen })} />
      </button>
      {isListOpen && (
        <ul>
          {transactionList.map((item) => (
            <li key={item.id}>
              <div>
                <span>{item.name}</span>
                <span>+{addComma(item.savings)}p</span>
              </div>
              <div>
                {item.species}&nbsp;&nbsp;|&nbsp;&nbsp;{item.date}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default TransactionList;
