import { ComponentProps } from "react";
import cx from "classnames";

import styles from "styles/common/button.module.scss";

/**
 * 조합: black + large | red + medium | white + medium | black + medium | light-grey + small
 *
 * ex. 
 *  <Button type="button" size="medium" color="black" onClick={() => console.log("button")}>
      닫기
    </Button>
 */

/* eslint-disable react/button-has-type */
type TProps = {
  size?: "small" | "medium" | "large" | "reactive";
  color?: "black" | "red" | "white" | "light-grey";
} & ComponentProps<"button">;

function Button({ size = "medium", color = "black", children, type, disabled, ...otherProps }: TProps) {
  return (
    <button
      className={cx(styles.button, styles[size], styles[color], { [styles.disabled]: disabled })}
      type={type}
      {...otherProps}
    >
      {children}
    </button>
  );
}

export default Button;
