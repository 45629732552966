import { MouseEventHandler, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "common/button";
import { Modal } from "common/modal";
import useModal from "hooks/useModal";
import DetailContent from "./DetailContent";
import { addComma } from "utils";

import { ReactComponent as Question } from "assets/svgs/question.svg";
import { TTransactionList } from "types/point";
import styles from "styles/pages/point/index.module.scss";
import TransactionList from "./TransactionList";
import Hr from "common/Hr";

type TProps = {
  point: number;
  expiringPoint: number;
  transactionList: TTransactionList[];
};

function Point({ point, expiringPoint, transactionList }: TProps) {
  const [modalContent, setModalContent] = useState<"transaction" | "question">("transaction");
  const [isModalOpen, onToggle] = useModal();
  const navigate = useNavigate();
  // 최근 1개월 거래내역 fetch

  const handleConvertClick = () => {
    navigate("convert");
  };

  const handleQuestionClick = () => {
    setModalContent("question");
    onToggle();
  };

  const handleTransactionDetailClick: MouseEventHandler<SVGElement> = (e) => {
    e.stopPropagation();
    setModalContent("transaction");
    onToggle();
  };

  return (
    <div className={styles.container}>
      <div className={styles.manage}>
        <div className={styles.point_box}>
          <div>
            <span>현재 보유 포인트</span>
            <span>{addComma(point)}P</span>
          </div>
          <div>
            <span>30일 내 소멸예정 포인트</span>
            <span>{addComma(expiringPoint)}P</span>
          </div>
        </div>
        <button type="button" onClick={handleQuestionClick} className={styles.question}>
          <Question />
          &nbsp;&nbsp;문의하기
        </button>
        <Button color="black" size="large" onClick={handleConvertClick}>
          씨앗으로 전환하기
        </Button>
      </div>

      <Hr />
      <TransactionList transactionList={transactionList} handleTransactionDetailClick={handleTransactionDetailClick} />
      <Modal isOpen={isModalOpen} onToggle={onToggle}>
        <DetailContent content={modalContent} />
      </Modal>
    </div>
  );
}

export default Point;
