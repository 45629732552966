import GNB from "common/GNB";
import { Detail, Progress, Result } from "pages";
import { Routes, Route } from "react-router-dom";
import styles from "styles/App.module.scss";

import List from "pages/list";
import Point from "pages/point";
import Convert from "pages/point/Convert";
import useScrollRestoration from "hooks/useScrollRestoration";

import { ReactComponent as CompanyPoint } from "assets/svgs/company_point.svg";

const transactionList = [
  {
    id: "1",
    name: "proposal name",
    savings: 18,
    species: "거래",
    date: "2021. 07. 12",
  },
  {
    id: "2",
    name: "proposal name",
    savings: 18,
    species: "거래",
    date: "2021. 07. 12",
  },
  {
    id: "3",
    name: "proposal name",
    savings: 18,
    species: "거래",
    date: "2021. 07. 12",
  },
  {
    id: "4",
    name: "proposal name",
    savings: 18,
    species: "거래",
    date: "2021. 07. 12",
  },
  {
    id: "5",
    name: "proposal name",
    savings: 18,
    species: "거래",
    date: "2021. 07. 12",
  },
  {
    id: "6",
    name: "proposal name",
    savings: 18,
    species: "거래",
    date: "2021. 07. 12",
  },
  {
    id: "7",
    name: "proposal name",
    savings: 18,
    species: "거래",
    date: "2021. 07. 12",
  },
  {
    id: "8",
    name: "proposal name",
    savings: 18,
    species: "거래",
    date: "2021. 07. 12",
  },
  {
    id: "9",
    name: "proposal name",
    savings: 18,
    species: "거래",
    date: "2021. 07. 12",
  },
];

function App() {
  useScrollRestoration();

  return (
    <div className={styles.wrapper}>
      <GNB />
      <main>
        <Routes>
          <Route path="/" element={<List />} />
          <Route path="/list" element={<List />} />

          <Route path="/detail/:id" element={<Detail />} />

          <Route path="/progress">
            <Route path=":id" element={<Progress />} />
            <Route path="result" element={<Result />} />
          </Route>

          <Route path="/point">
            <Route index element={<Point point={5200} expiringPoint={15} transactionList={transactionList} />} />
            <Route
              path="convert"
              element={
                <Convert
                  companyPointLogo={<CompanyPoint />}
                  unit="개"
                  currPoint={5200}
                  converter={(number) => Math.floor(number / 2)}
                />
              }
            />
          </Route>
        </Routes>
      </main>
    </div>
  );
}

export default App;
