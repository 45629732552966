import DescriptionList from "common/DescriptionList";

type TProps = {
  data: {
    Address: string;
    CEO: string;
    Name: string;
    RegistrationNo: string;
    Tel: string;
  };
};

export default function CompanyInfo({ data }: TProps) {
  const { Address, CEO, Name, RegistrationNo, Tel } = data;
  const dataList = [
    { title: "기업명", value: Name },
    { title: "담당자", value: CEO },
    { title: "사업자등록번호", value: RegistrationNo },
    { title: "대표번호", value: Tel },
    { title: "주소", value: Address },
  ];

  return (
    <section>
      <DescriptionList dataList={dataList} />
    </section>
  );
}
