import { WaterDrop } from "assets/svgs";
import styles from "styles/pages/detail/dataBox.module.scss";

export default function DataBox() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconBox}>
        <WaterDrop />
      </div>
      <div className={styles.date}>[6개월]</div>
      <div className={styles.txt}>의료데이터(건강검진 기록)</div>
    </div>
  );
}
