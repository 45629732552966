import { useState } from "react";
import dayjs from "dayjs";

import Summary from "./Summary";
import TabMenu from "./TabMenu";
import styles from "styles/pages/detail/index.module.scss";
import DataInfo from "./DataInfo";
import CompanyInfo from "./CompanyInfo";
import useDetailQuery from "./useDetailQuery";
import Hr from "common/Hr";

export default function Detail() {
  const { data } = useDetailQuery();

  const [isActiveLeft, setIsActiveLeft] = useState(true);
  const handleClick = (arg: boolean) => setIsActiveLeft(arg);

  if (!data) return null;
  return (
    <div className={styles.wrapper}>
      <Summary
        data={{
          imgUrl: data.Content.ImageURL.Banner,
          title: data.Content.Description.Title,
          amount: data.RewardPolicy.Amount,
          notBefore: dayjs(data.NotBefore).format("YYYY.MM.DD"),
          notAfter: dayjs(data.NotAfter).format("YYYY.MM.DD"),
        }}
      />
      <Hr />
      <TabMenu isActiveLeft={isActiveLeft} onClick={handleClick} />
      <div className={styles.content}>
        {isActiveLeft && (
          <DataInfo
            data={{
              category: data.Category,
              purpose: data.Content.Description.Detail,
            }}
          />
        )}
        {!isActiveLeft && <CompanyInfo data={data.Content.Description.Company} />}
      </div>
    </div>
  );
}
