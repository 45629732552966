import styles from "styles/common/GNB.module.scss";
import { DownIcon } from "assets/svgs";
import { logo } from "assets/imgs";
import { useLocation, useNavigate } from "react-router-dom";

export default function GNB() {
  const navigate = useNavigate();
  const handleBackClick = () => navigate(-1);
  const handleHomeClick = () => navigate("/");

  const { pathname } = useLocation();
  const title = getTitle(pathname);
  const canBack = title !== "거래";

  return (
    <header className={styles.wrapper}>
      {canBack && (
        <button type="button" name="back" aria-label="back" onClick={handleBackClick}>
          <DownIcon className={styles.arrow} />
        </button>
      )}
      <span>{title}</span>

      <button type="button" name="home" onClick={handleHomeClick}>
        <img src={logo} alt="logo" />
      </button>
    </header>
  );
}

function getTitle(path: string) {
  if (/detail|progress/g.test(path)) {
    return "데이터 마켓";
  }

  if (/point\/convert/g.test(path)) {
    return "포인트 전환";
  }

  if (/point/g.test(path)) {
    return "마이디 포인트";
  }

  return "거래";
}
