import { ReactNode, useState } from "react";
import cx from "classnames";
import { DownIcon } from "assets/svgs";
import styles from "styles/common/accordion.module.scss";

type TProps = {
  title: string;
  children: ReactNode;
};

export default function Accordion({ title, children }: TProps) {
  const [isVisible, setIsVisible] = useState(false);

  const handleClcik = () => setIsVisible((prev) => !prev);

  return (
    <div className={styles.wrapper}>
      <button type="button" className={styles.titleBox} onClick={handleClcik}>
        <h3>{title}</h3>
        <DownIcon className={cx({ [styles.active]: isVisible })} />
      </button>

      {isVisible && <div className={styles.content}>{children}</div>}
    </div>
  );
}
