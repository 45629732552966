import cx from "classnames";
import styles from "styles/pages/detail/tabMenu.module.scss";

type TProps = {
  isActiveLeft: boolean;
  onClick: (arg: boolean) => void;
};

export default function TabMenu({ isActiveLeft, onClick }: TProps) {
  return (
    <div className={styles.wrapper}>
      <button type="button" className={cx({ [styles.active]: isActiveLeft })} onClick={() => onClick(true)}>
        데이터 정보
      </button>
      <button type="button" className={cx({ [styles.active]: !isActiveLeft })} onClick={() => onClick(false)}>
        기업 정보
      </button>
    </div>
  );
}
