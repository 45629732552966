import styles from "styles/common/modal/modal.module.scss";
import Portal from "./Portal";
import { ReactComponent as XButton } from "assets/svgs/xButton.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useRef } from "react";

type TModal = {
  children: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
};

/**
 * ex.
 * 모달이 필요한 컴포넌트에서 아래와 같이 사용
 *
 * const [isOpen, onToggle] = useModal();
 *
 *     <button onClick={onToggle}>모달 열기</button>
 *     <Modal isOpen={isOpen} onToggle={onToggle}>모달 내용</Modal>
 */

export default function Modal({ children, isOpen, onToggle }: TModal) {
  const modalRef = useRef(null);

  useOnClickOutside(modalRef, onToggle);

  return (
    <Portal>
      {isOpen && (
        <div className={styles.wrapper}>
          <div className={styles.overlay}>
            <div ref={modalRef} className={styles.modal}>
              <XButton className={styles.xButton} onClick={onToggle} />
              {children}
            </div>
          </div>
        </div>
      )}
    </Portal>
  );
}
