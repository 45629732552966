import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getDetailData } from "api";

export default function useDetailQuery() {
  const { id = "" } = useParams(); // TODO: id falsy 처리

  return useQuery(["getDetailData", id], () => getDetailData(id), {
    enabled: !!id,
  });
}
