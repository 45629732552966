import { useNavigate } from "react-router-dom";
import cx from "classnames";

import Accordion from "common/Accordion";
import { Button } from "common/button";
import DescriptionList from "common/DescriptionList";
import CompanyInfo from "pages/detail/CompanyInfo";
import styles from "styles/pages/progress/index.module.scss";
import Guide from "./Guide";
import useModal from "hooks/useModal";
import { Modal } from "common/modal";
import logoImg from "assets/imgs/logo_myd.png";
import { getCommasInNum } from "utils";
import useDetailQuery from "pages/detail/useDetailQuery";
import Hr from "common/Hr";

export default function Progress() {
  const { data } = useDetailQuery();
  const navigate = useNavigate();
  const handleClcik = () => {
    navigate("/progress/result");
  };

  const [isOpen, handleToggle] = useModal();
  const handleFailClcik = () => handleToggle();

  if (!data) return null;
  const dataList = [
    { title: "거래명", value: data.Content.Description.Title },
    { title: "데이터 유형", value: "의료" },
    {
      title: "데이터 보유기간",
      value: (
        <p>
          판매일로부터 1년 <span className={styles.highlight}>(365일)</span>
        </p>
      ),
    },
    {
      title: "목적",
      value: data.Content.Description.Detail,
    },
    { title: "포인트", value: `${getCommasInNum(data.RewardPolicy.Amount)} P` },
  ];

  return (
    <section className={styles.wrapper}>
      <Guide />
      <Hr />

      <div className={styles.info}>
        <h3>거래정보</h3>
        <DescriptionList dataList={dataList} />
      </div>

      <Hr />
      <div className={cx(styles.content, styles.detailBox)}>
        <Accordion title="상세 데이터 내역">
          <p className={styles.detail}>
            [3개월/의료 데이터(건강검진내역)] 건강검진 기록,건강검진 결과, 건강검진 결과 요약, 건강검진 참고 데이터,
            검진 장소, 검진 연월일, 신장, 체중, 허리 둘레, 체질량 지수, 왼쪽 시력, 오른쪽 시력, 왼쪽 청력, 오른쪽 청력,
            최대 혈압, 최소 혈압, 요단백, 혈색소, 식전 혈당, 총 콜레스테롤, HDL 콜레스테롤, LDL 콜레스테롤,
            트리글리세라이드, 혈청크레아티닌, 신사구체여과율(GFR), AST(SGOT), ALT(SGPT), 감마지피티(y-GPT), 폐결핵
            흉부질환, 골다공증, 판정
          </p>
          <br />
          <p className={styles.detail}>
            [3개월/보험 데이터] 실손 보장형 지급 내역, 실손 보장형 지급 상세 내역, 실손 보장형 보험 계약 내용, 실손
            보장형 보험 보장 내용, 실손 보장형 보험 분석 내용, 정액 보장형 보험 계약 내용, 정액 보장형 보험 보장 내용,
            발생 일시, 마스킹 처리된 증권 번호, 회사 이름, 보험 상품명, 총 지급 금액, 지급 구분, 지급 사유, 지급 금액,
            지급 일자, 심사 결과, 홈페이지, 계약 상태, 보험사, 전화번호, 보장 시작일, 보장 종료일, 실손 구분, 보장 명칭,
            보장 금액, 보장 상태, 보험료, 종신 보장 여부, 납입 주기, 납입 기간, 약정 구분
          </p>
        </Accordion>
      </div>

      <Hr />
      <div className={styles.content}>
        <Accordion title="기업정보">
          <CompanyInfo data={data.Content.Description.Company} />
        </Accordion>
      </div>

      <div className={styles.btnBox}>
        <Button type="button" size="large" onClick={handleClcik}>
          모두 확인하였고, 동의합니다.
        </Button>
      </div>

      <Modal isOpen={isOpen} onToggle={handleToggle}>
        <article className={styles.modalContent}>
          <img src={logoImg} alt="logo" />
          <p>
            킥보드 사용 내역이 없어
            <br />
            거래에 실패했습니다.
          </p>
          <Button type="button" onClick={handleToggle}>
            확인
          </Button>
        </article>
      </Modal>
    </section>
  );
}
